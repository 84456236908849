import apiEndPoints from 'common/apiEndPoints';
import { performRequest, performRequestSecond, performRequestThird } from 'common/apiHandler';
import { getTenantDetails } from 'utils/utils';

export const fetchFiles = (obj) => {
  let {
    case_id,
    selectedFolder,
    selectedType,
    tag,
    is_read,
    query,
    pageLimit = 10,
    pagination_token = '',
    pagination_direction = 'forward',
  } = obj;
  const [tenantId, tenantSk] = getTenantDetails();
  // let query_param = tag;
  // if (query) {
  //   query_param = query;
  // }

  return performRequest(
    apiEndPoints.methodType.GET,
    `${
      apiEndPoints.fileManager.file
    }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&case_id=${
      case_id ?? 'undefined'
    }&folder_id=${selectedFolder ?? ''}&format=${selectedType ?? ''}&query=${query || tag || ''}&is_read=${is_read}`
  );
};

export const saveTags = (obj) => {
  let { data, is_lead = false, timeEntryData = '' } = obj;
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestSecond(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.fileManager.tagFile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const listFileTags = (case_id = '') => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.listFileTags}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}`
  );
};

export const getFileClients = (obj) => {
  let { case_id = '' } = obj;
  const [tenantId] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.fileClients}?tenant_id=${tenantId}&case_id=${case_id}`
  );
};

export const shareFile = (obj) => {
  let { case_id, data, is_lead, timeEntryData = '' } = obj;
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.fileSharing}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const createFile = (obj) => {
  let { data, is_lead = false, timeEntryData = '' } = obj;
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.fileManager.file}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const updateFile = (obj) => {
  let { data, is_lead = false, timeEntryData = '' } = obj;
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.fileManager.file}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};

export const getTemplates = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestSecond(apiEndPoints.methodType.GET, `/templates?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=100${params}`);
};

export const createTemplate = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(apiEndPoints.methodType.POST, `/templates?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const getFolders = (params) => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.file}/folders?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=100&${params}`
  );
};

export const listCasesForClientUsers = (obj) => {
  let { target_user_type, target_user_id, target_user_name, is_lead = true, is_events = false, keyword } = obj;

  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getUserCases}?tenant_sk=${tenantSk}&tenant_id=${tenantId}&target_user_type=${target_user_type}&target_user_id=${target_user_id}&target_user_name=${target_user_name}&is_lead=${is_lead}&is_events=${is_events}&keyword=${keyword}`;

  return performRequest(apiEndPoints.methodType.GET, url);
};

export const sendESigantureRequest = (obj) => {
  let { case_id, file_pk, file_sk, data, is_lead, timeEntryData = '' } = obj;

  const [tenantId] = getTenantDetails();

  let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.fileManager.sendESignature}?tenant_id=${tenantId}&case_id=${case_id}&file_pk=${filePk}&file_sk=${fileSk}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    data,
    timeEntryData
  );
};

export const presignedURLGet = (file_path, version_id = false, is_profile_image = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.presignedURLGet}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_path=${encodeURI(file_path)}${
    version_id ? `&version_id=${version_id}` : ''
  }${is_profile_image ? `&is_profile_image=${is_profile_image}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const deleteBulkFile = (data, operation, is_lead) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.bulkDeleteMoveFile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_operation=${operation}&is_lead=${is_lead}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const moveBulkFile = (data, operation, is_lead) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.bulkDeleteMoveFile}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_operation=${operation}&is_lead=${is_lead}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const validateUpload = (size) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.validateUpload}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&file_size=${size}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const sendESigntureRequestZoho = (obj) => {
  let { case_id, file_pk, file_sk, data, is_lead, timeEntryData = '' } = obj;

  const [tenantId, tenantSk] = getTenantDetails();

  let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${
      apiEndPoints.fileManager.sendESignatureZoho
    }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}&file_pk=${filePk}&file_sk=${fileSk}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    data,
    timeEntryData
  );
};

export const generateSignUrlZoho = (obj) => {
  let { case_id, file_pk, file_sk, data, is_lead, timeEntryData = '' } = obj;

  const [tenantId] = getTenantDetails();

  let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  data.host = window.origin;
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.fileManager.generateSignUrl}?tenant_id=${tenantId}&case_id=${case_id}&file_pk=${filePk}&file_sk=${fileSk}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    data,
    timeEntryData
  );
};
export const generateSignUrlZohoNoAuth = (obj) => {
  // const [tenantId] = getTenantDetails();

  // let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  // let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  obj.host = window.origin;
  return performRequestThird(apiEndPoints.methodType.POST, `${apiEndPoints.fileManager.generateSignUrlMail}`, obj, '', false);
};

export const getSignatureStatus = (obj, disableLoading) => {
  let { case_id, file_pk, file_sk, is_lead, timeEntryData = '' } = obj;

  const [tenantId] = getTenantDetails();

  let filePk = file_pk ? file_pk.replaceAll('#', '%23') : '';
  let fileSk = file_sk ? file_sk.replaceAll('#', '%23') : '';
  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.getSignatureStatus}?tenant_id=${tenantId}&case_id=${case_id}&file_pk=${filePk}&file_sk=${fileSk}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    {},
    timeEntryData,
    true,
    disableLoading
  );
};

export const copyFileUrl = (obj) => {
  let { data } = obj;

  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.fileManager.copyFileUrl}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

export const getSharedFiles = (obj) => {
  let { token, file_system_sk } = obj;
  // const [tenantId, tenantSk] = getTenantDetails();

  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.getSharedFiles}?token=${token}${file_system_sk ? `&file_system_sk=${file_system_sk}` : ''}`,
    null,
    null,
    false
  );
};

export const getSharedLinkStats = (obj) => {
  let { file_system_sk, lawft_link_id } = obj;
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.fileManager.getSharedLinkStats}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${
      file_system_sk ? `&file_system_sk=${file_system_sk}` : ''
    }${lawft_link_id ? `&lawft_link_id=${lawft_link_id}` : ''}`
  );
};

export const summarizeDocument = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();

  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.fileManager.summarizeDocument}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};

import React from 'react';
import { Tooltip } from 'primereact/tooltip';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function SummarizeFile(props) {
  const { rowData } = props;
  const { modals, handleSummarizeFile } = useFileManagerContext();
  const { setShowSummarizeModal } = modals;

  const handleSummarize = (rowData) => {
    const data = {
      file_path: rowData?.file_path,
      file_name: rowData?.file_name,
      version_id: rowData?.version_id,
      sk: rowData?.sk,
      regenerate: 0,
    };
    setShowSummarizeModal(true);
    handleSummarizeFile(rowData, data);
  };

  return (
    <>
      <i
        id="summarise-icon"
        onClick={() => handleSummarize(rowData)}
        className={'far fa-list-alt me-3 text-primary-main pointer'}
        aria-hidden="true"
      />
      <Tooltip content="Summarize" position="top" target="#summarise-icon" showDelay={500} />
    </>
  );
}

export default SummarizeFile;
